/**
 * Allows smooth kinetic scrolling of the surface
 */
module.exports = kinetic;

function kinetic(getPoint, scroll, settings) {
    if (typeof settings !== 'object') {
    // setting could come as boolean, we should ignore it, and use an object.
        settings = {};
    }

    const minVelocity = (typeof settings.minVelocity === 'number') ? settings.minVelocity : 5;
    const amplitude = (typeof settings.amplitude === 'number') ? settings.amplitude : 0.25;

    let lastPoint;
    let timestamp;
    const timeConstant = 342;

    let ticker;
    let vx,
        targetX,
        ax;
    let vy,
        targetY,
        ay;

    let raf;

    return {
        start,
        stop,
        cancel: dispose,
    };

    function dispose() {
        window.clearInterval(ticker);
        window.cancelAnimationFrame(raf);
    }

    function start() {
        lastPoint = getPoint();

        ax = ay = vx = vy = 0;
        timestamp = new Date();

        window.clearInterval(ticker);
        window.cancelAnimationFrame(raf);

        // we start polling the point position to accumulate velocity
        // Once we stop(), we will use accumulated velocity to keep scrolling
        // an object.
        ticker = window.setInterval(track, 100);
    }

    function track() {
        const now = Date.now();
        const elapsed = now - timestamp;
        timestamp = now;

        const currentPoint = getPoint();

        const dx = currentPoint.x - lastPoint.x;
        const dy = currentPoint.y - lastPoint.y;

        lastPoint = currentPoint;

        const dt = 1000 / (1 + elapsed);

        // moving average
        vx = 0.8 * dx * dt + 0.2 * vx;
        vy = 0.8 * dy * dt + 0.2 * vy;
    }

    function stop() {
        window.clearInterval(ticker);
        window.cancelAnimationFrame(raf);

        const currentPoint = getPoint();

        targetX = currentPoint.x;
        targetY = currentPoint.y;
        timestamp = Date.now();

        if (vx < -minVelocity || vx > minVelocity) {
            ax = amplitude * vx;
            targetX += ax;
        }

        if (vy < -minVelocity || vy > minVelocity) {
            ay = amplitude * vy;
            targetY += ay;
        }

        raf = window.requestAnimationFrame(autoScroll);
    }

    function autoScroll() {
        const elapsed = Date.now() - timestamp;

        let moving = false;
        let dx = 0;
        let dy = 0;

        if (ax) {
            dx = -ax * Math.exp(-elapsed / timeConstant);

            if (dx > 0.5 || dx < -0.5) moving = true;
            else dx = ax = 0;
        }

        if (ay) {
            dy = -ay * Math.exp(-elapsed / timeConstant);

            if (dy > 0.5 || dy < -0.5) moving = true;
            else dy = ay = 0;
        }

        if (moving) {
            scroll(targetX + dx, targetY + dy);
            raf = window.requestAnimationFrame(autoScroll);
        }
    }
}
