import { events } from '../utilities/custom-events';

export default class {
    constructor({
        id,
        errorHandle,
        inputName,
        conditionalName,
        conditionalValue,
    }) {
    // Elements and class variables
        const el = document.getElementById(id);
        const error = el.querySelector(errorHandle);
        const targets = conditionalName
            ? document.querySelectorAll(`[name="fields[${conditionalName}]"]`)
            : [];

        // Event handler functions
        function handleShowFormErrors(e) {
            const { errors } = e.detail;

            if (!(inputName in errors)) return;

            error.innerHTML = errors[inputName].join(', ');
        }
        function handleTargetChange(e) {
            el.style.display = e.currentTarget.value === conditionalValue ? 'block' : 'none';
        }

        // Add event listeners
        window.addEventListener(events.showFormErrors, handleShowFormErrors);
        targets.forEach(target => {
            target.addEventListener('change', handleTargetChange);
        });

        // Initalize conditional formatting
        if (conditionalName) {
            el.style.display = conditionalValue === inputName ? 'block' : 'none';
        }
    }
}
