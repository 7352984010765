import { scrollBottom } from '../utilities/scroll';

export default class {
    constructor({
        id,
        state,
    }) {
        const el = document.getElementById(id);
        // const video = el.querySelector('video');
        // const canvas = el.querySelector('canvas');
        // const context = canvas.getContext('2d');
        const scroll = el.querySelector('button');

        // Helper functions
        // function draw(w, h) {
        //   if (video.paused || video.ended) return;

        //   setTimeout(() => { draw(w, h); }, 20);
        // }

        // Event handler functions
        // function handlePlay() {
        //   const cw = Math.floor(canvas.clientWidth);
        //   const ch = Math.floor(canvas.clientHeight);

        //   canvas.width = cw;
        //   canvas.height = ch;

        //   video.play();
        //   draw(cw, ch);
        // }
        function handleScroll() {
            scrollBottom(el, state.headerNavHeight);
        }

        // Add event listeners
        // video.addEventListener('canplay', handlePlay);
        scroll.addEventListener('click', handleScroll);
    }
}
