import { events } from '../utilities/custom-events';
import { scrollTop } from '../utilities/scroll';

const ANIMATION_DURATION = 500; // miliseconds

export default class {
    constructor({
        id,
        contentHandle,
        lockedClass,
        activeClass,
        unloadingClass,
    }) {
    // Elements and class variables
        const el = document.getElementById(id);
        const content = el.querySelector(contentHandle);

        // Event handler functions
        function handleLockScroll() {
            document.body.classList.add(lockedClass);
        }
        function handleUnlockScroll() {
            document.body.classList.remove(lockedClass);
        }
        function handleUnloadRoute() {
            // Animate page unload and scroll to top
            el.classList.add(unloadingClass);
            setTimeout(() => { scrollTop(); }, ANIMATION_DURATION);
        }
        function handleShowOverlay() {
            // Allow children of content to specify their own z-index to overlay header/footer
            content.style.zIndex = 'initial';
        }
        function handleHideOverlay() {
            content.style.zIndex = '';
        }

        // Add event listeners
        window.addEventListener(events.lockScroll, handleLockScroll);
        window.addEventListener(events.unlockScroll, handleUnlockScroll);
        window.addEventListener(events.unloadRoute, handleUnloadRoute);
        window.addEventListener(events.showOverlay, handleShowOverlay);
        window.addEventListener(events.hideOverlay, handleHideOverlay);

        // Set initial state
        if (window.history && window.history.replaceState) {
            const replaceState = {
                ...window.history.state,
                url: window.location.href,
            };

            window.history.replaceState(replaceState, null);
        }

        // Initialize
        el.classList.add(activeClass);
    }
}
