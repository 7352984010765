import debounce from '../utilities/debouncer';
import { events, emitEvent } from '../utilities/custom-events';
import { scrollTop } from '../utilities/scroll';

export default class {
    constructor({
        id,
        overlayHandle,
        fixHandle,
        menuToggleHandle,
        navToggleHandle,
        backHandle,
        linkHandle,
        imageHandle,
        activeClass,
        overlayClass,
        fixedClass,
        hiddenClass,
        underlineClass,
        state,
    }) {
        const el = document.getElementById(id);
        const overlay = el.querySelector(overlayHandle);
        const fix = el.querySelector(fixHandle);
        // const headerLinks = el.querySelectorAll(`a:not(${menuToggleHandle})`);
        const menuToggles = el.querySelectorAll(menuToggleHandle);
        const menus = el.querySelectorAll(`${menuToggleHandle} + div`); // this isn't being used?
        const navToggle = el.querySelector(navToggleHandle);
        const backs = el.querySelectorAll(backHandle);
        const links = el.querySelectorAll(linkHandle);
        const images = el.querySelectorAll(imageHandle);

        // Global state
        state.headerHeight = el.offsetHeight;
        state.navHeight = fix.offsetHeight;

        // Component state
        let animating = false;
        // Keep track of live toggle so we can remove underline when other menus
        // are open and restore underline when they close
        const liveToggle = Array.from(menuToggles)
            .find(mt => window.location.href.includes(mt.href));

        let activeMenuToggle = null;

        // Helper functions
        function swapImage(i, url) {
            images[i].style.backgroundImage = `url(${url})`;
        }
        function enableMenuLinks(menu) {
            menu.querySelectorAll('a, button, input, select, label')
                .forEach(t => {
                    t.setAttribute('tabindex', '0');
                    t.setAttribute('aria-hidden', 'false');
                });
        }
        function disableMenuLinks(menu) {
            menu.querySelectorAll('a, button, input, select, label')
                .forEach(t => {
                    t.setAttribute('tabindex', '-1');
                    t.setAttribute('aria-hidden', 'true');
                });
        }

        // Event handler functions
        const handleResize = debounce(() => {
            state.headerHeight = el.offsetHeight;
            state.navHeight = fix.offsetHeight;
        }, 500);
        function handleUnloadRoute() {
            // Close header menus and nav
            el.classList.remove(activeClass);
            el.classList.remove(overlayClass);
            Array.from(menuToggles).forEach(mt => { mt.classList.remove(activeClass); });
            emitEvent(events.unlockScroll);
            disableMenuLinks(activeMenuToggle.nextElementSibling);
        }
        function handleShowHeader() {
            el.classList.remove(hiddenClass);
        }
        function handleHideHeader() {
            el.classList.add(hiddenClass);
        }
        function handleKeyup(e) {
            // Only care about escape key
            if (e.keyCode == 27) {
                // Call handleUnloadRoute to close header menus and nav, but focus stays inside the menu... how to handle? 
                handleUnloadRoute();
                // menuToggles.focus(); // this doesn't return focus to ONE item.
            }
        }

        function handleOverlay() {
            handleUnloadRoute();
        }
        function handleMenu(e = null) {
            e.preventDefault();

            activeMenuToggle = e.currentTarget;

            function cb() {
                const anyOpen = Array.from(menuToggles).reduce((acc, menuToggle) => {
                    const isOpen = menuToggle.classList.contains(activeClass);
                    const shouldOpen = menuToggle === activeMenuToggle && !isOpen;

                    if (shouldOpen) {
                        enableMenuLinks(menuToggle.nextElementSibling);
                    } else {
                        disableMenuLinks(menuToggle.nextElementSibling);
                    }

                    return menuToggle.classList.toggle(activeClass, shouldOpen) || el.classList.contains(activeClass) || acc
                }, false);


                // Make sure live toggle is not underlined if another menu is open
                if (liveToggle) {
                    liveToggle.classList.toggle(
                        underlineClass,
                        !(anyOpen && activeMenuToggle !== liveToggle),
                    );
                }

                el.classList.toggle(overlayClass, anyOpen);
                emitEvent(
                    anyOpen ? events.lockScroll : events.unlockScroll,
                    { cont: activeMenuToggle.nextSibling },
                );
            }

            if (fix.getBoundingClientRect().top > 0) {
                scrollTop(fix, -10, cb, true); // Offset by 1 to make sure logo shrinks
            } else {
                cb();
            }
        }
        const handleLinks = Array.from(links).map(link => () => {
            const index = link.getAttribute('data-image-index');
            const url = link.getAttribute('data-image-url');

            animating = true; // Prevent images from swapping until selection results in page load

            swapImage(index, url);
        });
        const handleMouseEnters = Array.from(links).map(link => () => {
            if (animating) return; // Do not swap image if one has been selected

            const index = link.getAttribute('data-image-index');
            const url = link.getAttribute('data-image-url');

            swapImage(index, url);
        });
        function handleMouseExit() {
            if (animating) return; // Do not swap image if one has been selected

            // Revert all images back to base url
            Array.from(images).forEach((image, i) => {
                const url = image.getAttribute('data-image-url');

                swapImage(i, url);
            });
        }
        function handleNav() {
            function cb() {
                emitEvent(
                    el.classList.toggle(activeClass) ? events.lockScroll : events.unlockScroll,
                    { cont: el.querySelector('.header__navs-cont__primary-cont') },
                );
            }

            if (fix.getBoundingClientRect().top > 0) {
                scrollTop(fix, -1, cb); // Offset by 1 to make sure logo shrinks
            } else {
                cb();
            }
        }
        function handleNavObserver(entries) {
            el.classList.toggle(fixedClass, entries[0].boundingClientRect.top < 0);
        }

        // Enable event listeners
        window.addEventListener('resize', handleResize);
        window.addEventListener(events.closeHeader, handleUnloadRoute);
        window.addEventListener(events.showHeader, handleShowHeader);
        window.addEventListener(events.hideHeader, handleHideHeader);
        overlay.addEventListener('click', handleOverlay);
        document.addEventListener('keyup', handleKeyup);
        Array.from(menuToggles).forEach(menuToggle => {
            disableMenuLinks(menuToggle.nextElementSibling);
            menuToggle.addEventListener('click', handleMenu);
        });
        navToggle.addEventListener('click', handleNav);
        Array.from(links).forEach((link, i) => {
            link.addEventListener('click', handleLinks[i]);
            link.addEventListener('mouseenter', handleMouseEnters[i]);
            link.addEventListener('mouseleave', handleMouseExit);
        });
        Array.from(backs).forEach(back => {
            back.addEventListener('click', handleMenu);
        });
        const navObserver = new IntersectionObserver(handleNavObserver, { threshold: 1 });
        navObserver.observe(fix);
    }
}
