import YouTubePlayer from 'youtube-player';
import { events, emitEvent } from '../utilities/custom-events';

export default class {
    constructor({
        id,
        videoId,
    }) {
        const el = document.getElementById(id);
        const cont = el.querySelector('div');

        const player = YouTubePlayer(cont, {
            playerVars: { rel: 0, showinfo: 0, ecver: 2 },
        });

        function handleCloseModal() {
            player.pauseVideo();
        }
        function handleStateChange(e) {
            if (e.data === 0) {
                emitEvent(events.closeModal, { clean: true });
            }
        }

        window.addEventListener(events.closeModal, handleCloseModal);
        player.on('stateChange', handleStateChange);

        player.loadVideoById(videoId);
        player.playVideo();
    }
}
