import { recaptchaSiteKey } from '../config';
import { events, emitEvent } from '../utilities/custom-events';
import { post } from '../utilities/router';
import { syncDates, validateRecaptcha } from '../utilities/forms';

export default class {
    constructor({
        id,
        redirectUri,
    }) {
    // Elements and class variables
        const el = document.getElementById(id);
        const form = el.querySelector('form');
        const dates = form.querySelectorAll('[type="date"]');
        const submit = form.querySelector('[type="submit"]');
        const formElements = form.elements;

        let processing = false;

        // When the window loads, check to see if there is a query string and if so,
        // put the values of each key in an array. Place values in corresponding
        // fields in the long form.
        if (window.location.search) {
            const searchParams = new URLSearchParams(window.location.search);

            if (formElements['fields-date3']) {
                formElements['fields-date3'].value = searchParams.get('arrivaldate');
            }
            if (formElements['fields-date4']) {
                formElements['fields-date4'].value = searchParams.get('departuredate');
            }
            if (formElements['fields-number4']) {
                formElements['fields-number4'].value = searchParams.get('adultcount');
            }
            if (formElements['fields-number3']) {
                formElements['fields-number3'].value = searchParams.get('childcount');
            }
        }

        // Event handler functions
        async function handleSubmit(e) {
            e.preventDefault();

            if (processing) {
                return;
            }

            processing = true;
            submit.setAttribute('disabled', true);

            const isValid = await validateRecaptcha(recaptchaSiteKey);

            if (!isValid) {
                processing = false;
                submit.removeAttribute('disabled');

                console.error('Invalid reCAPTCHA request'); // eslint-disable-line no-console

                return;
            }

            const formData = new FormData(form);

            // Handle post response
            function cb(res) {
                emitEvent(events.stopLoader);

                const json = JSON.parse(res);

                // Handle errors
                if (json.errors) {
                    processing = false;
                    submit.removeAttribute('disabled');
                    emitEvent(events.showFormErrors, { errors: json.errors });

                    return;
                }

                // Handle success - Disable current form and redirect
                if (json.success) {
                    form.removeEventListener('submit', handleSubmit);
                    form.remove();
                    window.location = redirectUri;
                }
            }

            emitEvent(events.startLoader);
            post({ formData, cb });
        }

        // Initialize
        form.addEventListener('submit', handleSubmit);

        if (dates.length === 2) {
            syncDates(...dates);
        }
    }
}
