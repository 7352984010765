module.exports = makeSvgController;

function makeSvgController(svgElement) {
    const elementValid = (svgElement instanceof SVGElement);
    if (!elementValid) {
        throw new Error('svg element is required for svg.panzoom to work');
    }

    const owner = svgElement.ownerSVGElement;
    if (!owner) {
        throw new Error('Do not apply panzoom to the root <svg> element. ' +
      'Use its child instead (e.g. <g></g>). ' +
      'As of March 2016 only FireFox supported transform on the root element');
    }

    owner.setAttribute('tabindex', 1); // TODO: not sure if this is really polite

    const api = {
        getBBox,
        getScreenCTM,
        getOwner,
        applyTransform,
        initTransform,
    };

    return api;

    function getOwner() {
        return owner;
    }

    function getBBox() {
        const bbox = svgElement.getBBox();
        return {
            left: bbox.x,
            top: bbox.y,
            width: bbox.width,
            height: bbox.height,
        };
    }

    function getScreenCTM() {
        return owner.getScreenCTM();
    }

    function initTransform(transform) {
        const screenCTM = svgElement.getScreenCTM();
        transform.x = screenCTM.e;
        transform.y = screenCTM.f;
        transform.scale = screenCTM.a;
        owner.removeAttributeNS(null, 'viewBox');
    }

    function applyTransform(transform) {
        svgElement.setAttribute('transform', `matrix(${
            transform.scale} 0 0 ${
            transform.scale} ${
            transform.x} ${transform.y})`);
    }
}
