import './polyfills';

// Components
import CookieBanner from './components/cookie-banner';
import DetailHero from './components/detail-hero';
import DetailNavigation from './components/detail-navigation';
import DotsCarousel from './components/dots-carousel';
import DropdownField from './components/dropdown-field';
import FavoriteProperty from './components/favorite-property';
import FilterableSearch from './components/filterable-search';
import Field from './components/field';
import Footer from './components/footer';
import Form from './components/form';
import FloatingContactButton from './components/floating-contact-button';
import FloatingLabelField from './components/floating-label-field';
import Galleries from './components/galleries';
import Header from './components/header';
import Hero from './components/hero';
import Highlights from './components/highlights';
import ImageCarousel from './components/image-carousel';
import InteractiveMap from './components/interactive-map';
import Link from './components/link';
import ListCarousel from './components/list-carousel';
import Loader from './components/loader';
import Main from './components/main';
import MapListings from './components/map-listings';
import Modal from './components/modal';
import Navigation from './components/navigation';
import PaginatedSearch from './components/paginated-search';
import Plan from './components/plan-card';
import PreferenceForm from './components/preference-form';
import ProcessSteps from './components/process-steps';
import PropertySearch from './components/property-search';
import PropertiesMap from './components/properties-map';
import SliderCarousel from './components/slider-carousel';
import SaveSearchButton from './components/save-search-button';
import SaveSearchForm from './components/save-search-form';
import UserForm from './components/user-form';
import Video from './components/video';
import VisitFrom from './components/visit-form';

// Utilities
import { instantiate } from './utilities/components';
import { enableAmobeePixels } from './utilities/events';
import { enableAmobeeAwarenessPixels } from './utilities/awareness';

/* eslint-disable quote-props */
const classMap = {
    'cookie-banner': CookieBanner,
    'detail-hero': DetailHero,
    'detail-navigation': DetailNavigation,
    'dots-carousel': DotsCarousel,
    'dropdown-field': DropdownField,
    'favorite-property': FavoriteProperty,
    'field': Field,
    'filterable-search': FilterableSearch,
    'footer': Footer,
    'form': Form,
    'floating-contact-button': FloatingContactButton,
    'floating-label-field': FloatingLabelField,
    'galleries': Galleries,
    'header': Header,
    'hero': Hero,
    'highlights': Highlights,
    'image-carousel': ImageCarousel,
    'interactive-map': InteractiveMap,
    'link': Link,
    'list-carousel': ListCarousel,
    'loader': Loader,
    'modal': Modal,
    'main': Main,
    'map-listings': MapListings,
    'navigation': Navigation,
    'paginated-search': PaginatedSearch,
    'plan-card': Plan,
    'preference-form': PreferenceForm,
    'process-steps': ProcessSteps,
    'property-search': PropertySearch,
    'properties-map': PropertiesMap,
    'slider-carousel': SliderCarousel,
    'save-search-button': SaveSearchButton,
    'save-search-form': SaveSearchForm,
    'user-form': UserForm,
    'video': Video,
    'visit-form': VisitFrom,
};
/* eslint-enable quote-props */

// Event handler functions
function handleDOMConentLoaded() {
    // Call component constructors
    instantiate(classMap);
    enableAmobeePixels();
    enableAmobeeAwarenessPixels();
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);
