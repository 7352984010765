import Swiper from 'swiper';

export default class {
    constructor({
        id,
        contHandle,
        prevHandle,
        nextHandle,
        pageHandle,
    }) {
    // Params
        const el = document.getElementById(id);
        const cont = el.querySelector(contHandle);
        const prev = el.querySelector(prevHandle);
        const next = el.querySelector(nextHandle);
        const page = el.querySelector(pageHandle);

        // Initialize slider
        new Swiper(cont, { // eslint-disable-line no-new
            loop: true,
            effect: 'fade',
            fade: { crossFade: true },
            navigation: {
                prevEl: prev,
                nextEl: next,
            },
            pagination: {
                el: page,
            },
        });
    }
}
