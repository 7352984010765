// Had to create lib directory for Panzoom library pending acceptance of PR:
// https://github.com/anvaka/panzoom/pull/50
// Still need module installed via NPM to connect dependencies
import panzoom from '../lib/panzoom';
import { scrollTop } from '../utilities/scroll';

const MIN_ZOOM = 1;
const MAX_ZOOM = 2;

export default class {
    constructor({
        id,
        startHandle,
        mapHandle,
        zoomHandle,
        panHandle,
        panelHandle,
        activeClass,
        state,
    }) {
        const el = document.getElementById(id);
        const start = el.querySelector(startHandle);
        const map = el.querySelector(mapHandle);
        const image = map.querySelector('img');
        const zoom = el.querySelector(zoomHandle);
        const zoomControls = zoom.querySelectorAll('button');
        const zoomIn = zoomControls[0];
        const zoomOut = zoomControls[1];
        const pan = el.querySelector(panHandle);
        const panControls = pan.querySelectorAll('button');
        const panUp = panControls[0];
        const panRight = panControls[1];
        const panDown = panControls[2];
        const panLeft = panControls[3];
        const icons = Array.from(map.querySelectorAll('em'));
        const toggles = Array.from(el.querySelectorAll('[type="checkbox"][name="types"]'));
        const panels = Array.from(el.querySelectorAll(panelHandle));
        const closes = Array.from(panels.map(panel => panel.querySelector('button')))
            .filter(close => close);

        let pz = null;

        // Helper functions
        function init() {
            pz = panzoom(map, {
                bounds: true,
                boundsPadding: 1,
                boundsDisabledForZoom: true,
                maxZoom: MAX_ZOOM,
                minZoom: MIN_ZOOM,
                beforeWheel: () => true, // Ignore wheel zoom
                onTouch: () => false, // Ignore wheel zoom
            });

            el.classList.add(activeClass);
            panels[0].classList.add(activeClass);

            // Compensate icon scale on map transform
            pz.on('transform', () => {
                const scale = pz.getTransform().scale || 1;

                icons.forEach(icon => {
                    icon.style.transform = `scale(${1 / scale}) translate(-50%, -50%)`;
                });

                // MAX_ZOOM check requires threshold
                if (scale >= MAX_ZOOM - 0.05) {
                    zoomIn.setAttribute('disabled', true);
                } else {
                    zoomIn.removeAttribute('disabled');
                }
                if (scale <= MIN_ZOOM) {
                    zoomOut.setAttribute('disabled', true);
                } else {
                    zoomOut.removeAttribute('disabled');
                }
            });
        }

        // Event handler functions
        function handleResize() {
            const contRatio = map.parentElement.offsetWidth / map.parentElement.offsetHeight;
            const imageRatio = image.naturalWidth / image.naturalHeight;

            // Reset map container and image dimensions
            map.style.width = 'auto';
            map.style.height = 'auto';
            image.style.width = 'auto';
            image.style.height = 'auto';

            // Set map and image dimensions per container aspect ratio
            if (contRatio > imageRatio) {
                map.style.width = '100%';
                image.style.width = '100%';
                image.style.marginLeft = '0';
            } else {
                map.style.width = `${image.offsetWidth}px`;
                map.style.height = '100%';
                image.style.width = `${image.offsetWidth}px`;
                image.style.height = '100%';
                image.style.marginLeft = `${(map.offsetWidth - image.offsetWidth) / 2}px`;
            }

            // Reset transform
            if (pz) {
                pz.smoothZoom(map.offsetWidth / 2, map.offsetHeight / 2, 0);
            }
        }
        function handleStart(e) {
            e.preventDefault();

            init();
            handleResize();
        }
        function handleZoomIn() {
            pz.smoothZoom(map.offsetWidth / 2, map.offsetHeight / 2, MAX_ZOOM);
        }
        function handleZoomOut() {
            pz.smoothZoom(map.offsetWidth / 2, map.offsetHeight / 2, 0);
        }
        function handlePanUp() {
            pz.moveBy(0, 100, true);
        }
        function handlePanRight() {
            pz.moveBy(-100, 0, true);
        }
        function handlePanDown() {
            pz.moveBy(0, -100, true);
        }
        function handlePanLeft() {
            pz.moveBy(100, 0, true);
        }
        function handleIcon(e) {
            const icon = e.currentTarget;
            const panelIndex = parseInt(icon.getAttribute('data-panel-index'), 10);
            const rect = icon.getBoundingClientRect();
            const offset = icon.offsetWidth / 2;

            function cb() {
                icons.forEach(i => { i.classList.toggle(activeClass, i === icon); });
                panels.forEach((panel, i) => {
                    panel.classList.toggle(activeClass, i === panelIndex);
                });

                pz.centerOn(icon);
                pz.smoothZoom(rect.left + offset, rect.top + offset, MAX_ZOOM);
            }

            scrollTop(el, state.navHeight, cb);
        }
        function handleToggle(e) {
            const toggle = e.currentTarget;

            icons
                .filter(i => i.getAttribute('data-type') === toggle.value)
                .forEach(i => { i.style.display = toggle.checked ? 'block' : 'none'; });
        }
        function handleClose() {
            panels.forEach((panel, i) => { panel.classList.toggle(activeClass, i === 0); });
            icons.forEach(icon => { icon.classList.remove(activeClass); });
        }

        // Add event listeners
        window.addEventListener('resize', handleResize);
        start.addEventListener('click', handleStart);
        image.addEventListener('load', handleResize);
        zoomIn.addEventListener('click', handleZoomIn);
        zoomOut.addEventListener('click', handleZoomOut);
        panUp.addEventListener('click', handlePanUp);
        panRight.addEventListener('click', handlePanRight);
        panDown.addEventListener('click', handlePanDown);
        panLeft.addEventListener('click', handlePanLeft);
        icons.forEach(icon => {
            icon.addEventListener('click', handleIcon);
        });
        toggles.forEach(toggle => {
            toggle.addEventListener('change', handleToggle);
        });
        closes.forEach(close => {
            close.addEventListener('click', handleClose);
        });

        scrollTop(el);
    // Temp init of map to avoid introduction modal
    // init();
    // handleResize();
    }
}
